<template>
  <b-container
    v-if="loaded && !selectedProductConfirmed"
    id="product-slide"
    class="container-fluid"
  >
    <slide-heading
      :heading="currentSlide.heading"
    />


    <div class="slide-description mb-2 justify-content-center">
      <div v-html="currentSlideDescription" />
    </div>

    <b-row v-if="slideProducts">
      <b-card-group deck :class="{ horizontal }">
        <product-card
          v-for="slideProduct in slideProducts"
          :key="slideProduct.id"
          :slide-product="slideProduct"
          :slide-jurisdiction="jurisdiction"
          @chosen="chooseProductHandler(slideProduct)"
        />
      </b-card-group>
    </b-row>

    <ct-centered-spinner v-else>
      loading products...
    </ct-centered-spinner>

    <div v-if="formationHasNoDiyProducts">
      <span class="processing-filing-link" @click="goToProcessingFilingSlide">
        I will file this online directly with the state myself.
      </span>
    </div>

    <product-options-confirm-modal
      :bus="bus"
      :loading="!loaded"
      @productAdded="productAdded"
    />

    <stageline-alert-modal
      :message="stagelineAlertMessage"
    />
  </b-container>

  <ct-centered-spinner v-else>
    Loading products...
  </ct-centered-spinner>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import Vue from 'vue'
import { logSlideProductAdded } from '@/components/StagelineV2/helper.js'
import * as DOMPurify from 'dompurify'

export default {
  name: 'FilingOptionsSlide',
  components: {
    ProductCard:                () => import('@/components/StagelineV2/cards/ProductCard'),
    ProductOptionsConfirmModal: () => import('@/components/StagelineV2/modals/ProductOptionsConfirmModal'),
    StagelineAlertModal:        () => import('@/components/StagelineV2/modals/StagelineAlertModal'),
    CtCenteredSpinner:          () => import('@/components/shared/CtCenteredSpinner'),
    SlideHeading:               () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slideProducts: Array,
  },
  data() {
    return {
      loaded: false,
      submittingSelectedProduct: false,
      selectedProductConfirmed: false,
      bus: new Vue,
      stagelineAlertMessage: '<p>Looks like you already have a Formation Filing in your cart.</p>' +
        '<p>Remove the Formation Filing from the cart in order to select a different one.</p>',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'formationType',
      'jurisdiction',
      'company',
      'currentSlide',
      'processedFormData',
    ]),
    ...mapGetters('checkout', [
      'formationProductInCart',
      'productsLoaded',
    ]),
    ...mapGetters('companies', [
      'formationFiling',
    ]),
    ...mapGetters('stageline', [
      'currentSlideLayoutSubType',
    ]),
    horizontal() {
      return this.slideProducts.length > 3
    },
    noDiyProducts() {
      return !this.slideProducts.some(product => product.product_kind === 'diy')
    },
    formationHasNoDiyProducts() {
      return this.currentSlideLayoutSubType === 'form a company' && this.noDiyProducts
    },
    currentSlideDescription() {
      return DOMPurify.sanitize(this.currentSlide.description)
    },
  },
  async mounted() {
    // Make sure processedFormData is available to include formData on shoppingBasketItem creation
    if (this.company && _.isEmpty(this.processedFormData) && !this.readOnly) {
      await this.fetchProcessedFormData()
    }
    if (!this.productsLoaded) {
      await this.loadProducts()
    }

    this.loaded = true
  },
  methods: {
    ...mapActions('stageline', [
      'setFormationType',
      'navigateToAndCompleteStageSlidesBeforeEndpoint',
    ]),
    ...mapActions('stagelineSchemaForm', [
      'fetchProcessedFormData',
    ]),
    ...mapActions('checkout', [
      'loadProducts',
    ]),
    async chooseProductHandler(slideProduct) {
      if (slideProduct.product_kind === 'diy') {
        if (this.formationProductAlreadyActiveOrInCart(slideProduct)) {
          this.formationFiling ? this.$bvModal.show('product-confirm-modal') :
            this.$bvModal.show('product-already-in-cart-modal')
        } else {
          if (slideProduct.product_category === 'form a company') await this.setFormationType('mail')
        }
        return
      }

      this.bus.$emit('open-product-options-confirm', [slideProduct.product])
    },
    formationProductAlreadyActiveOrInCart(sp) {
      return (sp?.product_category === 'form a company' && (this.formationProductInCart || this.formationFiling))
    },
    async goToProcessingFilingSlide() {
      await this.setFormationType('mail')
      await this.navigateToAndCompleteStageSlidesBeforeEndpoint('form_the_company__filing_status__pending_order')
    },
    async productAdded(productAdded) {
      await logSlideProductAdded(productAdded)
    },
  },
}
</script>

<style scoped lang="scss">
#product-slide {
  h3 {
    text-align: left;
  }
  .card-deck {
    width: 100%;
    justify-content: center;
    &.horizontal {
      flex-flow: row wrap;
      flex-grow: 0;
      .card {
        min-height: unset;
        max-height: unset;
        min-width: unset;
        max-width: unset;
        height: 17.5em;
        flex: 0 0 28.125em;
        flex-flow: row;
        .card-body {
          text-align: left;
          .card-title {
            text-align: center;
          }
        }
        .card-footer {
          display: flex;
          flex-flow: column;
          justify-content: center;
          min-width: 11.25em;
          button {
            width: 100%;
          }
          border-left: $ct-ui-border-1 solid $ct-ui-color-22;
          margin-top: 1.5em;
          margin-bottom: 1.5em;
        }
      }
    }
  }

  .processing-filing-link {
    display: block;
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
